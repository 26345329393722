var render = function () {
  var _vm$data, _vm$data$body, _vm$data2, _vm$data2$body, _vm$data3, _vm$data3$body, _vm$data4, _vm$data4$body, _vm$data5, _vm$data5$body, _vm$data6, _vm$data6$body, _vm$data7, _vm$data7$body, _vm$data8, _vm$data8$testimoni, _vm$data9, _vm$data9$testimoni, _vm$data10, _vm$data10$testimoni, _vm$data11, _vm$data11$button, _vm$data12, _vm$data12$button, _vm$data14, _vm$data14$button;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "width": "100%",
      "max-width": "1270px",
      "margin": ['0 auto', '16px auto'],
      "background": "linear-gradient(0deg, rgba(223,240,238,1) 0%, rgba(245,237,210,1) 38%, rgba(255,255,255,1) 74%)",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['1rem', '3rem']
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "gap": "30px",
      "text-align": "center",
      "width": ['100%', '85%'],
      "margin": "auto"
    }
  }, [!!((_vm$data = _vm.data) !== null && _vm$data !== void 0 && (_vm$data$body = _vm$data.body) !== null && _vm$data$body !== void 0 && _vm$data$body.image) ? _c('c-image', {
    attrs: {
      "display": ['none', 'block'],
      "width": ['200px', '250px'],
      "src": (_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : (_vm$data2$body = _vm$data2.body) === null || _vm$data2$body === void 0 ? void 0 : _vm$data2$body.image,
      "alt": "image"
    }
  }) : _vm._e(), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "align-items": ['center', 'start'],
      "text-align": "start"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "20px",
      "size-desktop": "28px",
      "color": "primary.600",
      "text-align": ['center', 'start']
    }
  }, [_vm._v(" " + _vm._s((_vm$data3 = _vm.data) === null || _vm$data3 === void 0 ? void 0 : (_vm$data3$body = _vm$data3.body) === null || _vm$data3$body === void 0 ? void 0 : _vm$data3$body.header) + " ")]), _c('BaseDivider', {
    attrs: {
      "border-color": "#005A64"
    }
  }), !!((_vm$data4 = _vm.data) !== null && _vm$data4 !== void 0 && (_vm$data4$body = _vm$data4.body) !== null && _vm$data4$body !== void 0 && _vm$data4$body.image) ? _c('c-image', {
    attrs: {
      "display": ['block', 'none'],
      "max-width": "250px",
      "width": "100%",
      "margin": "auto",
      "src": (_vm$data5 = _vm.data) === null || _vm$data5 === void 0 ? void 0 : (_vm$data5$body = _vm$data5.body) === null || _vm$data5$body === void 0 ? void 0 : _vm$data5$body.image,
      "alt": "image"
    }
  }) : _vm._e(), _c('BaseText', {
    attrs: {
      "margin-top": "8px",
      "size-mobile": "16px",
      "size-desktop": "18px",
      "text-align": ['justify', 'start']
    }
  }, [_vm._v(" " + _vm._s((_vm$data6 = _vm.data) === null || _vm$data6 === void 0 ? void 0 : (_vm$data6$body = _vm$data6.body) === null || _vm$data6$body === void 0 ? void 0 : _vm$data6$body.subheader) + " ")]), _c('BaseText', {
    attrs: {
      "margin-top": "16px",
      "size-mobile": "12px",
      "size-desktop": "16px",
      "text-align": ['justify', 'start']
    }
  }, [_vm._v(" " + _vm._s((_vm$data7 = _vm.data) === null || _vm$data7 === void 0 ? void 0 : (_vm$data7$body = _vm$data7.body) === null || _vm$data7$body === void 0 ? void 0 : _vm$data7$body.description) + " ")])], 1)], 1), _c('c-flex', {
    attrs: {
      "margin-top": "20px",
      "align-items": "center",
      "gap": "30px",
      "text-align": "center",
      "width": ['100%', '85%'],
      "margin": "24px auto 0 auto",
      "flex-direction": ['column', 'row']
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "14px-3",
      "size-desktop": "20px",
      "color": "primary.600",
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s((_vm$data8 = _vm.data) === null || _vm$data8 === void 0 ? void 0 : (_vm$data8$testimoni = _vm$data8.testimoni) === null || _vm$data8$testimoni === void 0 ? void 0 : _vm$data8$testimoni.header) + " ")]), _c('c-box', {
    attrs: {
      "width": "100%",
      "margin": ['16px auto 0 auto', '16px auto'],
      "background-color": "white",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": "16px",
      "padding": "16px 30px",
      "text-align": "start",
      "color": "primary.400"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "12px",
      "size-desktop": "16px",
      "text-align": "justify",
      "font-style": "italic"
    }
  }, [_vm._v(" " + _vm._s((_vm$data9 = _vm.data) === null || _vm$data9 === void 0 ? void 0 : (_vm$data9$testimoni = _vm$data9.testimoni) === null || _vm$data9$testimoni === void 0 ? void 0 : _vm$data9$testimoni.statement) + " ")]), _c('BaseText', {
    attrs: {
      "margin-top": "16px",
      "size-mobile": "12px",
      "size-desktop": "16px"
    }
  }, [_vm._v(" " + _vm._s((_vm$data10 = _vm.data) === null || _vm$data10 === void 0 ? void 0 : (_vm$data10$testimoni = _vm$data10.testimoni) === null || _vm$data10$testimoni === void 0 ? void 0 : _vm$data10$testimoni.client) + " ")])], 1)], 1), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "width": "100%",
      "justify-content": "center",
      "align-items": "center",
      "gap": "16px"
    }
  }, [!!((_vm$data11 = _vm.data) !== null && _vm$data11 !== void 0 && (_vm$data11$button = _vm$data11.button) !== null && _vm$data11$button !== void 0 && _vm$data11$button.image) ? _c('c-image', {
    attrs: {
      "width": ['150px', '220px'],
      "src": (_vm$data12 = _vm.data) === null || _vm$data12 === void 0 ? void 0 : (_vm$data12$button = _vm$data12.button) === null || _vm$data12$button === void 0 ? void 0 : _vm$data12$button.image,
      "alt": "image"
    }
  }) : _vm._e(), _c('BaseButton', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        color: 'primary.400'
      },
      expression: "{\n          color: 'primary.400'\n        }"
    }],
    attrs: {
      "color": "secondary",
      "size": "small",
      "border-radius": "16px"
    },
    on: {
      "click": function click($event) {
        var _vm$data13, _vm$data13$button;

        return _vm.$router.push(((_vm$data13 = _vm.data) === null || _vm$data13 === void 0 ? void 0 : (_vm$data13$button = _vm$data13.button) === null || _vm$data13$button === void 0 ? void 0 : _vm$data13$button.link) || '#');
      }
    }
  }, [_vm._v(" " + _vm._s((_vm$data14 = _vm.data) === null || _vm$data14 === void 0 ? void 0 : (_vm$data14$button = _vm$data14.button) === null || _vm$data14$button === void 0 ? void 0 : _vm$data14$button.label) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }