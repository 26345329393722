<template>
  <c-box
    width="100%"
    max-width="1270px"
    :margin="['0 auto', '16px auto']"
    background="linear-gradient(0deg, rgba(223,240,238,1) 0%, rgba(245,237,210,1) 38%, rgba(255,255,255,1) 74%)"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :padding="['1rem', '3rem']"
  >
    <!--  1  -->
    <c-flex
      align-items="center"
      gap="30px"
      text-align="center"
      :width="['100%', '85%']"
      margin="auto"
    >
      <c-image
        v-if="!!data?.body?.image"
        :display="['none', 'block']"
        :width="['200px', '250px']"
        :src="data?.body?.image"
        alt="image"
      />
      <c-flex
        flex-direction="column"
        :align-items="['center', 'start']"
        text-align="start"
      >
        <BaseText
          size-mobile="20px"
          size-desktop="28px"
          color="primary.600"
          :text-align="['center', 'start']"
        >
          {{ data?.body?.header }}
        </BaseText>
        <BaseDivider border-color="#005A64" />
        <c-image
          v-if="!!data?.body?.image"
          :display="['block', 'none']"
          max-width="250px"
          width="100%"
          margin="auto"
          :src="data?.body?.image"
          alt="image"
        />
        <BaseText
          margin-top="8px"
          size-mobile="16px"
          size-desktop="18px"
          :text-align="['justify', 'start']"
        >
          {{ data?.body?.subheader }}
        </BaseText>
        <BaseText
          margin-top="16px"
          size-mobile="12px"
          size-desktop="16px"
          :text-align="['justify', 'start']"
        >
          {{ data?.body?.description }}
        </BaseText>
      </c-flex>
    </c-flex>

    <!--  2  -->
    <c-flex
      margin-top="20px"
      align-items="center"
      gap="30px"
      text-align="center"
      :width="['100%', '85%']"
      margin="24px auto 0 auto"
      :flex-direction="['column', 'row']"
    >
      <c-flex
        flex-direction="column"
        align-items="center"
        justify-content="center"
      >
        <BaseText
          size-mobile="14px-3"
          size-desktop="20px"
          color="primary.600"
          text-align="center"
        >
          {{ data?.testimoni?.header }}
        </BaseText>
        <c-box
          width="100%"
          :margin="['16px auto 0 auto', '16px auto']"
          background-color="white"
          :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
          border-radius="16px"
          padding="16px 30px"
          text-align="start"
          color="primary.400"
        >
          <BaseText
            size-mobile="12px"
            size-desktop="16px"
            text-align="justify"
            font-style="italic"
          >
            {{ data?.testimoni?.statement }}
          </BaseText>
          <BaseText
            margin-top="16px"
            size-mobile="12px"
            size-desktop="16px"
          >
            {{ data?.testimoni?.client }}
          </BaseText>
        </c-box>
      </c-flex>
      <c-flex
        flex-direction="column"
        width="100%"
        justify-content="center"
        align-items="center"
        gap="16px"
      >
        <c-image
          v-if="!!data?.button?.image"
          :width="['150px', '220px']"
          :src="data?.button?.image"
          alt="image"
        />
        <BaseButton
          v-chakra="{
            color: 'primary.400'
          }"
          color="secondary"
          size="small"
          border-radius="16px"
          @click="$router.push(data?.button?.link || '#')"
        >
          {{ data?.button?.label }}
        </BaseButton>
      </c-flex>
    </c-flex>
  </c-box>
</template>

<script>
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'
import BaseDivider from '@/components/elements/base-divider.vue'
import { reqProgramKonsultasi } from '@/requests/dietela-api/client/program-konsultasi'

export default {
  name: 'PageNotFound',
  components: {
    BaseDivider,
    BaseButton,
    BaseText,
  },
  data() {
    return {
      data: null,
    }
  },
  async mounted() {
    const data = await reqProgramKonsultasi.notifPage(this.$store.getters.axios)
    if (!data?.data?.data) {
      this.$router.push({ name: 'client.index' })
      return
    }
    this.data = data?.data?.data
  },
}
</script>
